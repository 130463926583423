<template>
  <section>
    <BaseElForm label-position="top">
      <BaseElFormItem label="訂金金額">
        $ {{ orderDeposit.price }}
      </BaseElFormItem>
      <BaseElFormItem label="付款方式">
        <BaseElRadioGroup v-model="syncModel">
          <div class="flex flex-col" style="gap: 12px; margin-top: 12px">
            <BaseElRadio
              v-for="type in paymentOptions"
              :key="type.value"
              :label="type.value"
              :disabled="disabledWallet(type.value)"
            >
              <span v-if="type.value === 'wallet'">{{ type.name }}（本次使用：${{ orderDeposit.price }} / 使用後餘額：${{walletBalance - orderDeposit.price }}）</span>
              <span v-else>{{ type.name }}</span>
            </BaseElRadio>
          </div>
        </BaseElRadioGroup>
      </BaseElFormItem>
    </BaseElForm>
  </section>
</template>

<script>
import { depositPaymentTypes, staticDepositPaymentTypes } from '@/config/deposit'
import { FindMember } from '@/api/member'
import { map, get } from 'lodash'
import { mapGetters } from 'vuex'
import { i18n } from '@/plugins/i18n/i18n'

export default {
  name: 'BlockDeposit',
  props: {
    orderDeposit: {
      type: Object,
      default: () => ({}),
    },
    model: {
      type: String,
      default: '',
    },
    depositConfig: {
      type: Object,
      default: () => ({}),
    },
    depositPaymentConfig: {
      type: Array,
      default: () => [],
    },
    memberData: {
      type: Object,
      defauilt: () => ({}),
    },
  },
  data: () => ({
    payment: '',
    member: {},
  }),
  computed: {
    ...mapGetters(['shop']),
    memberId () {
      return get(this.memberData, 'member.id')
    },
    walletBalance () {
      return get(this.member, 'Wallet.balance') || 0
    },
    paymentOptions () {
      // const options = this.depositPaymentConfig.map(i => depositPaymentTypes[i.type])
      const options = map(depositPaymentTypes, i => ({
        name: i18n.t(i.name),
        value: i.value,
        type: i.type,
      }))
      if (!this.orderDeposit.price) return [{ name: '免付訂金', value: 'free' }]
      if (!this.memberData.isMember) return options.filter(i => i.type === 'offline')
      return options.filter(i => i.type === 'offline' || i.type === 'wallet')
    },

    syncModel: {
      get () { return this.model },
      set (val) { return this.$emit('update:model', val) },
    },
  },
  watch: {
    paymentOptions () {
      if (!this.orderDeposit.price) this.syncModel = 'free'
      else this.syncModel = this.paymentOptions[0].value
    },
    async memberId (id) {
      await this.findMember(id)
      this.defaultPayment()
    },
  },
  async mounted () {
    await this.findMember(this.memberId)
    this.defaultPayment()
  },
  methods: {
    disabledWallet (type) {
      if (type === 'wallet' && this.walletBalance === 0) return true
      const left = this.walletBalance - this.orderDeposit.price
      if (type === 'wallet' && left < 0) return true
      return false
    },
    async findMember (id) {
      if (!id) return
      const res = await FindMember({
        shopId: this.shop,
        id,
      })
      this.member = res
    },
    defaultPayment () {
      if (!this.orderDeposit.price) this.syncModel = 'free'
      else {
        if (this.paymentOptions.length) {
          if (this.disabledWallet('wallet')) {
            const options = this.paymentOptions.filter(i => i.value !== 'wallet')
            if (options.length) this.syncModel = options[0].value
          } else {
            this.syncModel = this.paymentOptions[0].value
          }
        }
      }
    },
  },
}
</script>

<style lang="postcss" scoped>
</style>
